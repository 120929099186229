import {
    TimeSeriesData,
    LegendData,
    LineStyleType,
    LegendStyleType,
    TickFormat,
    InteractivityPreference,
    TimeSeriesChartData,
} from 'src/shared/types';
import moment from 'moment';
import { availableAreaColors } from 'src/shared/constants/colors';

type habitatInsightsTrasformedChartData = {
    chartData: TimeSeriesChartData[];
    legendData: LegendData[];
    isDataWithBufferArea: boolean;
    isDataWithAnnualRange: boolean;
    tickslist: string[];
};

export const transformHabitatInsightsChartData = (
    timeSeriesData: TimeSeriesData[],
    bufferArea: string,
    annualRange: string,
    getColourForHabitatInsights: (type: string) => string
): habitatInsightsTrasformedChartData => {
    const output: habitatInsightsTrasformedChartData = {
        chartData: [],
        legendData: [],
        isDataWithBufferArea: false,
        isDataWithAnnualRange: false,
        tickslist: [],
    };

    const colorMap: { [key: string]: string } = {};
    let colorIndex = 0;
    const getColorForString = (str: string): string => {
        // Check if color already exists for this string
        if (!colorMap[str]) {
            // If not, assign a color from available colors
            // The modulus (%) ensures that colorIndex wraps around if it exceeds the array length
            colorMap[str] = availableAreaColors[colorIndex % availableAreaColors.length];
            colorIndex++;
        }
        return colorMap[str];
    };

    timeSeriesData.forEach(timeSeriesEntry => {
        timeSeriesEntry.group.map(metric => {
            let chartDataEntry = output.chartData.find(o => o.type === metric.type);

            if (!chartDataEntry) {
                let groupObjColor = getColourForHabitatInsights(metric.type);
                if (groupObjColor === '#000') {
                    groupObjColor = getColorForString(metric.type);
                }
                chartDataEntry = {
                    type: metric.type,
                    data: [],
                    color: groupObjColor,
                    label: metric.type,
                    groupType: 'group' + metric.type,
                };

                output.chartData.push(chartDataEntry);
                output.legendData.push({
                    label: metric.type,
                    type: metric.type,
                    color: groupObjColor,
                    groupType: 'group' + metric.type,
                    interactivityPreference: InteractivityPreference.GROUP_TYPE,
                });
            }

            const yearFromDateTime = moment(parseInt(timeSeriesEntry.datetime)).format(TickFormat.YEAR_ONLY);

            chartDataEntry.data.push({
                date: parseInt(yearFromDateTime),
                value: metric.value,
            });

            if (metric.boundary) {
                output.isDataWithBufferArea = true;
            }

            if (metric.boundary && bufferArea === 'show') {
                let bufferAreaObj = output.chartData.find(o => o.label === metric.type + ' Buffer Area');
                if (!bufferAreaObj) {
                    bufferAreaObj = {
                        type: 'Habitat Insights Buffer Area',
                        data: [],
                        color: getColourForHabitatInsights(metric.type),
                        label: metric.type + ' Buffer Area',
                        styleType: LineStyleType.DASHED,
                        groupType: 'group' + metric.type,
                    };
                    output.chartData.push(bufferAreaObj);
                }

                bufferAreaObj.data.push({
                    date: parseInt(yearFromDateTime),
                    value: metric.boundary,
                });
            }

            if (metric.annualRange) {
                output.isDataWithAnnualRange = true;
            }

            if (metric.annualRange && annualRange === 'show') {
                let annualRangeObj = output.chartData.find(o => o.label === metric.type + ' Annual Range');
                if (!annualRangeObj) {
                    annualRangeObj = {
                        type: 'Habitat Insights Annual Range',
                        data: [],
                        color: getColourForHabitatInsights(metric.type),
                        label: metric.type + ' Annual Range',
                        styleType: LineStyleType.AREA,
                    };
                    output.chartData.push(annualRangeObj);
                }

                annualRangeObj.data.push({
                    date: parseInt(yearFromDateTime),
                    value: [metric.annualRange.min, metric.annualRange.max],
                });
            }
        });
    });

    if (output.isDataWithBufferArea && bufferArea === 'show') {
        output.legendData.push({
            label: 'Buffer area',
            type: 'Habitat Insights Buffer Area',
            color: '#D9D9D9',
            styleType: LegendStyleType.DASHED,
            marginTop: '18px',
        });
    }

    if (output.isDataWithAnnualRange && annualRange === 'show') {
        output.legendData.push({
            label: 'Annual range',
            type: 'Habitat Insights Annual Range',
            color: '#C1C1C1',
            styleType: LegendStyleType.AREA,
            marginTop: '18px',
        });
    }

    if (output.chartData.length > 0) {
        output.tickslist = output.chartData[0].data.map(value => value.date.toString());
    }

    return output;
};
